<template>
    <section id="residence_card">
        <div class="residence-wrapper">
            <div
                v-if="!isCreator"
                class="residence"
                @click="goToResidence"
                :class="{
                    complex: type == residenceType.COMPLEX,
                    simplified: type == residenceType.SIMPLIFIED,
                    basic: type == residenceType.BASIC,
                    lastRes: id == lastResidenceId,
                }"
            >
                <div class="resume">
                    <h1>{{ name }}</h1>
                    <h2>{{ adress }}</h2>
                </div>
                <div class="details">
                    <div class="left-details mobile">
                        <update-status-tag
                            :status="updateStatus"
                            :showLabel="false"
                            from="residence"
                        ></update-status-tag>
                        <span
                            v-if="
                                updateStatus === 'needupdate' ||
                                updateStatus === 'error'
                            "
                        >
                            <i id="update" class="fas fa-sync btn-blue"></i>
                        </span>
                        <span v-if="parseInt(ipass) === 1">
                            <img
                                class="type-icon"
                                :src="imageBaseURL + 'Images/ipass_icon.png'"
                            />
                        </span>
                    </div>
                    <div class="right-details mobile">
                        <div class="type-icon-wrapper">
                            <img
                                v-if="type == residenceType.COMPLEX"
                                class="type-icon"
                                :src="
                                    require('@/assets/images/buildings/complex-residence-small.png')
                                "
                                alt="complex residence"
                            />
                            <img
                                v-else-if="type == residenceType.SIMPLIFIED"
                                class="type-icon"
                                :src="
                                    require('@/assets/images/buildings/simplified-residence-small.png')
                                "
                                alt="simplified residence"
                            />
                            <img
                                v-else-if="type == residenceType.BASIC"
                                class="type-icon"
                                :src="
                                    require('@/assets/images/buildings/basic-residence-small.png')
                                "
                                alt="basic residence"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="checkRight('G_RESIDENCES')"
                class="residence creator"
                @click="createResidence"
            >
                <div class="resume">
                    <div class="resume-style"></div>
                </div>
                <div class="details">
                    <div class="left-details"></div>
                    <div class="right-details">
                        <div>
                            <div class="create-picto">
                                <i class="fas fa-city"></i>
                                <i class="fas fa-plus"></i>
                            </div>
                            <h1>{{ $t("residenceCard.create") }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import UpdateStatusTag from "@/components/UpdateStatusTag.vue"

import v1mixin from "@/mixins/v1.js"

import { residenceType } from "@/enums"

export default {
    name: "ResidenceCard",
    components: {
        UpdateStatusTag,
    },
    props: {
        id: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        adress: {
            type: String,
            default: "",
        },
        updateStatus: {
            type: String,
            default: "",
        },
        ipass: {
            type: String,
            default: "",
        },
        isCreator: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [v1mixin],
    data() {
        return {
            residenceType,
            lastResidenceId: null,
            imageBaseURL: process.env.VUE_APP_IMAGES_URL,
        }
    },
    methods: {
        goToResidence(e) {
            if (e.target.id === "update") {
                this.goToV1View("data/majs/list/rid=" + this.id)
            } else {
                this.$router.push({
                    name: "Residence",
                    params: {
                        id: this.id,
                    },
                })
            }
        },
        createResidence() {
            this.$router.push({
                name: "CreateResidences",
            })
        },
    },
    created() {
        if (this.$store.getters["account/getResidenceId"] !== null) {
            this.lastResidenceId = this.$store.getters["account/getResidenceId"]
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.residence-wrapper {
    display: flex;
    justify-content: center;
}
.residence {
    width: 225px;
    height: 225px;
    border-radius: 30px;
    transition: background-color 200ms, color 200ms;
    .resume {
        height: 40%;
        border-radius: 30px 30px 0 0;
        padding: 20px 25px;

        h1 {
            font-size: $normal;
            font-family: $font_avenir_heavy;
            word-wrap: break-word;
            margin: 0 !important;
            width: 100% !important;
            padding: 0 !important;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h2 {
            font-size: $small;
            word-wrap: break-word;
            position: relative;
            margin: 0 !important;
            width: 100% !important;
            padding: 0.3em 0 0 0 !important;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .details {
        border-radius: 0 0 30px 30px;
        height: 60%;
        display: flex;

        .left-details {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;
            width: 40%;
            border-radius: 0 0 0 30px;
            padding: 10px;

            .type-icon {
                width: 18px;
            }
        }

        .right-details {
            width: 60%;
            border-radius: 30px 0 25px 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
                padding: 0 !important;
            }

            .type-icon-wrapper {
                .type-icon {
                    pointer-events: none;
                    width: 100px;
                }
            }
        }
    }
}

.residence:hover {
    cursor: pointer;
}

.creator {
    background-color: $orange-creator-dark;
    transition: background-color 200ms, color 200ms;

    .resume {
        height: 30%;
        background-color: $orange-creator-neutral;
        padding: 0;
        transition: background-color 200ms, color 200ms;

        .resume-style {
            background-color: $orange-neutral;
            transition: background-color 200ms, color 200ms;
            width: 100%;
            height: 100%;
            border-radius: 30px 30px 30px 0;
        }
    }

    .details {
        height: 70%;

        .left-details {
            width: 30%;
        }

        .right-details {
            background-color: $orange-creator-neutral;
            transition: background-color 200ms, color 200ms;
            width: 70%;

            h1 {
                font-size: $normal;
                color: $white;
                font-family: $font_avenir_heavy;
                width: 100px;
                margin-top: 10px;
                transition: font-size 200ms;
            }

            .create-picto {
                color: $white;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                .fa-city {
                    font-size: $normal;
                    transition: font-size 200ms;
                }

                .fa-plus {
                    font-size: $big;
                    transition: font-size 200ms;
                    padding: 0 0 28px 0;
                }
            }
        }
    }
}

.creator:hover {
    background-color: $orange-creator-light;

    .resume {
        background-color: $orange-creator-lighter;

        .resume-style {
            background-color: $orange-creator-light;
        }
    }

    .details {
        .right-details {
            background-color: $orange-creator-lighter;
        }
    }
}

.complex {
    background-color: $blue-residence-neutral;
    transition: background-color 200ms, border-color 200ms, box-shadow 200ms;

    .details {
        .right-details {
            background-color: $blue-residence-dark;
            transition: background-color 200ms;
        }
    }
}

.complex:hover {
    background-color: $white;
    box-shadow: 0px 0 25px 0 rgb(0 0 0 / 20%);

    .details {
        .right-details {
            background-color: $blue-residence-neutral;
        }
    }
}

.simplified {
    background-color: $orange-residence-neutral;
    transition: background-color 200ms, border-color 200ms, box-shadow 200ms;

    .details {
        .right-details {
            background-color: $orange-residence-dark;
            transition: background-color 200ms;
        }
    }
}

.simplified:hover {
    background-color: $white;
    box-shadow: 0px 0 25px 0 rgb(0 0 0 / 25%);

    .details {
        .right-details {
            background-color: $orange-residence-neutral;
        }
    }
}

.basic {
    background-color: $grey-residence-neutral;
    transition: background-color 200ms, border-color 200ms, box-shadow 200ms;

    .details {
        .right-details {
            background-color: $grey-residence-dark;
            transition: background-color 200ms;
        }
    }
}

.basic:hover {
    background-color: $white;
    box-shadow: 0px 0 25px 0 rgb(0 0 0 / 25%);

    .details {
        .right-details {
            background-color: $grey-residence-neutral;
            transition: background-color 200ms;
        }
    }
}

.lastRes {
    box-shadow: inset 1px 1px 10px rgb(0 0 0 / 15%), 2px 2px 10px #668899;
}

.btn-blue {
    background-color: $blue-light;
    color: $white;
    padding: 5px;
    border-radius: 30px;
}
</style>
